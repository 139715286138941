import axios from 'axios'

let contactUsHeader
let contactUsForm
let contactUsFormResponse

const captureUTMCodes = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  if (urlParams) {
    const utmSource = urlParams.get('utm_source')
    const utmCampaign = urlParams.get('utm_campaign')
    const utmMedium = urlParams.get('utm_medium')
    const utmTerm = urlParams.get('utm_term')

    const inputSource = document.querySelector('#lead_utm_source')
    const inputCampaign = document.querySelector('#lead_utm_campaign')
    const inputMedium = document.querySelector('#lead_utm_medium')
    const inputTerm = document.querySelector('#lead_utm_term')

    if (utmSource && inputSource) { inputSource.value = utmSource }
    if (utmCampaign && inputCampaign) { inputCampaign.value = utmCampaign }
    if (utmMedium && inputMedium) { inputMedium.value = utmMedium }
    if (utmTerm && inputTerm) { inputTerm.value = utmTerm }
  }
}

const submitHandler = (event) => {
  const leadData = new FormData(contactUsForm)

  if (contactUsForm.checkValidity()) {
    event.preventDefault()

    const fields = document.querySelectorAll('#contact-us form .field')

    fields.forEach((field) => {
      field.classList.remove('error')

      const messageContainer = field.querySelector('.message')
      messageContainer.innerHTML = null
    })

    axios.post(
      '/leads',
      leadData
    ).then((response) => {
      contactUsHeader.classList.add('hidden')
      contactUsForm.classList.add('hidden')
      contactUsFormResponse.classList.remove('hidden')
    }).catch((error) => {
      // console.log('')
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('error (request incomplete): ', error)
      // console.log('error.response (request incomplete): ', error.response)
      // console.log('error.response.data (request incomplete): ', error.response.data)
      // console.log('--------------------------------------')

      if (error && error.response && error.response.data) {
        const errors = error.response.data

        if (errors) {
          const keys = Object.keys(errors)
          keys.forEach((key) => {
            const errorMessages = errors[key]

            // console.log('key: ', key)
            // console.log('errorMessages: ', errorMessages)
            // console.log('--------------------------------------')

            const input = document.querySelector(`#contact-us form #lead_${key}`)
            // console.log('input: ', input)
            // console.log('--------------------------------------')

            const field = input.parentElement
            // console.log('field: ', field)
            // console.log('--------------------------------------')

            const errorContainer = document.querySelector(`#contact-us form #lead_${key} + .message`)
            // console.log('errorContainer: ', errorContainer)
            // console.log('--------------------------------------')

            // Set highlight border
            field.classList.add('error')

            // Set error message(s)
            let errorString = ''
            errorMessages.forEach((errorMessage) => {
              errorString += `<span class="error-description">"${key}" ${errorMessage}</span>`
            })

            errorContainer.innerHTML = errorString
          })
        }

        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log('')
      }
    }).finally(() => {
    })
  } else {
    console.log('form is invalid')
  }
}

// --------------------------------------------------------------------------------------------------
// "Ready"
// --------------------------------------------------------------------------------------------------
const init = (event = null) => {
  contactUsHeader = document.querySelector('#contact-us header')
  contactUsForm = document.querySelector('#contact-us form')
  contactUsFormResponse = document.querySelector('#contact-us #form-response-message')

  if (contactUsForm.length) {
    captureUTMCodes()

    contactUsForm.addEventListener('submit', submitHandler)
  }
}

if (document.readyState !== 'loading') {
  init()
} else {
  document.addEventListener('DOMContentLoaded', init)
}
